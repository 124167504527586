import React, { Fragment } from 'react'
import { AppBar, Toolbar, Typography } from '@material-ui/core'

export default function header() {
    return (
        <Fragment>
            <AppBar position="fixed">
                <Toolbar>
                    <Typography variant="h6">
                        Interest Hunt
                    </Typography>
                    <p style={{marginLeft:"10px"}}> [Finding you the best returns across all commercial banks in Nepal] </p>
                    <img src="nepalflag.gif" height='30px' style={{marginLeft:"10px"}}></img>
                </Toolbar>
            </AppBar>
        </Fragment>
    );
}