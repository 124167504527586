import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useFetch } from './hooks';

const columns = [
  { id: 'bankName', label: 'Bank Name', minWidth: 270, maxWidth: 270, align: 'left' },
  {
    id: 'fixedDepositRate',
    label: 'Interest Rate - Fixed',
    minWidth: 170,
    maxWidth: 170,
    align: 'right'
  },
  {
    id: 'savingsRate',
    label: 'Interest Rate - Savings',
    minWidth: 170,
    maxWidth: 170,
    align: 'right'
  },
//   {
//     id: 'website',
//     label: 'Website',
//     minWidth: 170,
//     align: 'left'
//   }
//   {
//     id: 'interestRateSource',
//     label: 'Interest Source URL',
//     minWidth: 170,
//     align: 'left'
//   },
];

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  tableWrapper: {
    minHeight: 550,
    maxHeight: 550,
    overflow: 'auto'
  },
  putAtCenter : {
      margin: 'auto'
  }
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [data, loading] = useFetch(
    "https://api.interesthunt.com/banks"
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <Paper className={classes.root}>
            <div className ={classes.putAtCenter}>
            <div className={classes.tableWrapper}>
                <Table stickyHeader  size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(datum => {
                    return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={datum.id}>
                        {columns.map(column => {
                        const value = datum[column.id];
                        return (
                            <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                        );
                    })}
                </TableRow>
              );
            })}
                    </TableBody>
                </Table>
            </div>
            </div>
            <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
        </Paper>
      )}
    </>
  );
}

// https://medium.com/@cwlsn/how-to-fetch-data-with-react-hooks-in-a-minute-e0f9a15a44d6
