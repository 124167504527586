import React, { Fragment } from "react";
import StickyHeadTable from "./stickyHeadTable";
import RandomImage from "./randomImage";
import { Grid, Paper } from "@material-ui/core";

const style = {
    Paper: {
        padding: 20,
        marginTop: 10,
        marginBottom: 10
    }
}

export default function MainPage() {
  return (
    <Fragment>
        <Grid container spacing={2}>
        <Grid item sm> <Paper style={style.Paper}> <StickyHeadTable></StickyHeadTable> </Paper> </Grid>
        <Grid item sm> <Paper style={style.Paper}> <RandomImage></RandomImage> </Paper> </Grid>
      </Grid>
    </Fragment>
  );
}
