import React, { Fragment } from 'react';
import Header from "./components/header";
import Footer from './components/footer';
import MainPage from './components/mainPage'

function App() {
  return (
    <Fragment>
      <Header></Header>
      <div style={{marginTop:"64px"}}>
      <MainPage></MainPage>
      </div>
      <Footer></Footer>
    </Fragment>
  );
}

export default App;
