import React from 'react';
import { Grid } from '@material-ui/core';

export default function Footer() {
    return(
        <Grid container>
            <Grid item sm>
                <p style = {{marginLeft:'10px'}}>Created (with passion) by Punit Man Shrestha.</p>
            </Grid>
        </Grid>
    );
}